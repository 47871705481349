import React from 'react';

// import logo from '../images/logo_wit.webp';
import logoVl from '../images/logo-sin-vl.webp';
import logoNl from '../images/stop-it-now-logo.svg';

export default function Footer() {
  return (
    <footer className="global-footer d-print-none">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-3 text-left">
            <p className="footer-cta text-left">
              Heb je vragen, ben je op zoek naar hulp of advies of wil je feedback geven?
              <br />
              <br />
              Neem contact op met Stop it Now Vlaanderen of Stop it Now Nederland.
              <br />
              <br />
              <strong>Bel ons anoniem en gratis of mail ons</strong>
            </p>
          </div>
          <div className="col-md-4 text-center">
            <a href="https://stopitnow.be" target="_blank" rel="noreferrer">
              <img alt="Logo Nederland" className="logo-footer" src={logoVl} />
            </a>
            <p>
              <strong>Vlaanderen</strong>
            </p>
            <p className="footer-contact">
              <a href="tel:080020050" target="_blank" rel="noreferrer">
                0800 200 50
              </a>
              <br />
              <a href="mailto:vragen@stopitnow.be" target="_blank" rel="noreferrer">
                vragen@stopitnow.be
              </a>
            </p>
          </div>
          <div className="col-md-4 text-center">
            <a href="https://stopitnow.nl" target="_blank" rel="noreferrer">
              <img alt="Logo Nederland" className="logo-footer" src={logoNl} />
            </a>
            <p>
              <strong>Nederland</strong>
            </p>
            <p className="footer-contact">
              <a href="tel:08002666436" target="_blank" rel="noreferrer">
                0800 266 64 36
              </a>
              <br />
              <a href="https://stopitnow.nl/contact" target="_blank" rel="noreferrer">
                Contactgegevens
              </a>
              <br />
              <a href="https://chat.stopitnow.nl/chat" target="_blank" rel="noreferrer">
                Chat met ons
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
